* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.editor-wrapper {
  position: absolute;
  top: 9rem;
  width: 100%;
  bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.ProseMirror {
  position: relative;
  width: 209.9mm;
  min-height: 297mm;
  padding: 0 30mm;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  background-color: white;
  overflow: hidden;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.ProseMirror table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.ProseMirror table th,
.ProseMirror table td {
  border: 1pt solid rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  position: relative;
  vertical-align: top;
}

.ProseMirror table th {
  background-color: #f1f3f5;
  font-weight: bold;
  text-align: left;
}

.ProseMirror table td.selectedCell:after {
  background: rgba(200, 200, 255, 0.4);
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.ProseMirror table td.column-resize-handle {
  background-color: #adf;
  bottom: -2px;
  position: absolute;
  right: -2px;
  pointer-events: none;
  top: 0;
  width: 4px;
}

.ProseMirror table p {
  margin: 0;
}

.ProseMirror .template {
  background-color: #e0e0e0;
  padding: 1px 6px;
  border-radius: 16px;
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.ProseMirror-focused:focus {
  outline: none;
}

.page-break {
  position: absolute;
  width: 210mm;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.12);
}
