.shepherd-header {
  background-color: transparent !important;
}
body {
  font-family: 'poppins' !important;
}
::-webkit-scrollbar {
  width: 4px; /* Adjust the width to your preference */
}

/* Set the style of the scrollbar track (the background part) */
::-webkit-scrollbar-track {
  /* background: #f1f1f1;  */
  background: transparent;
}

/* Set the style of the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #888888a1; /* Grey thumb */
  border-radius: 6px;
}

/* Change the thumb color on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker grey on hover */
}

.shepherd-element {
  border: 3px solid black !important;
  padding: 14px !important;
}

.shepherd-button {
  font-family: inherit;
}

.show-skip {
  display: block;
}

.hide-skip {
  display: none;
}
